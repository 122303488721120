import { reducers } from '@lls/core'
import auth from '@resources/store/auth.js'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'

const configureStore = () => {
  const loggerMiddleware = createLogger({
    duration: true,
    timestamp: false,
    collapsed: true
  })
  return {
    ...createStore(
      combineReducers({
        ...reducers,
        auth
      }),
      applyMiddleware(loggerMiddleware)
    )
  }
}

export default configureStore
