import { normalizeData } from '@resources/schema/index.js'
import { gqlNoCache } from './graphql'

export const fetchUserApi = ({ token }) =>
  gqlNoCache({
    query: `query resourcesFetchUserApi ($token: String!) {
      viewer(token: $token) {
        me {
          id
          isPremium
          booksV2 {
            id
            uri
          }
        }
      }
    }
`,
    variables: {
      token
    }
  }).then(normalizeData)
