import { KIT_CLASSNAME_FREE_LIGHT_THEME, Stamp } from '@lls/lls-kit'

import cssModule from './header.module.css'

const Header = ({ premiumResources }) => {
  const { category, type } = premiumResources?.[0] || {}

  return (
    <div className={cssModule.header}>
      <Stamp className={KIT_CLASSNAME_FREE_LIGHT_THEME} theme='red' size='small'>
        {type}
      </Stamp>
      <h3 className={cssModule.title}>{category?.title}</h3>
      <h4 className={cssModule.subtitle}>{category?.subtitle}</h4>
    </div>
  )
}

export default Header
