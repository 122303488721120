import { Button } from '@lls/lls-kit'

import { makeComponent } from '@lls/utils'
import cssModule from './common.module.css'

export const StyledResourceTitle = makeComponent('h3', cssModule.resourceTitle)

export const StyledResourceSubtitle = makeComponent('h4', cssModule.resourceSubtitle)

export const StyledResourcesList = makeComponent('div', cssModule.resourcesList)

export const StyledResourceCard = makeComponent('div', cssModule.resourceCard)

export const ModalFloatingCloseButton = ({ onClick }) => (
  <Button className={cssModule.button} inverted theme='gray' icon='cancel' size='small' onClick={onClick} />
)
