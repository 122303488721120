import { makeReducer } from '@lls/utils'
import _ from 'lodash/fp'

import { actionTypes } from '@resources/actions/index.js'

const initialState = {
  user: {
    id: null,
    token: null,
    isPremium: null
  },
  allowedBooks: null
}

const auth = makeReducer(initialState, {
  [actionTypes.SET_AUTH]: _.assign,
  [actionTypes.RESET_AUTH]: _.constant(initialState)
})

export default auth
