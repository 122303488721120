import { Button, Modal, ZoomFrame } from '@lls/lls-kit'
import { StyledComingSoonStamp } from '@lls/superkit'
import _ from 'lodash/fp'
import { useCallback, useState } from 'react'

import { StyledResourceCard, StyledResourceSubtitle, StyledResourceTitle, StyledResourcesList } from './common'

import Header from './header'
import cssModule from './imagesContent.module.css'

const ImageCard = ({ resource, onClick }) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const disabled = !!resource?.comingSoon

  const onClickCb = useCallback(() => {
    if (disabled) return
    onClick(resource)
  }, [onClick, resource, disabled])

  const showZoomModal = () => {
    setIsZoomed(true)
  }

  const hideZoomModal = () => {
    setIsZoomed(false)
  }

  return (
    <>
      <StyledResourceCard className={cssModule.imageCard} onClick={onClickCb} data-disabled={+disabled}>
        {resource?.comingSoon && <StyledComingSoonStamp>{resource?.comingSoon}</StyledComingSoonStamp>}
        {disabled ? (
          <img className={cssModule.image} src='/assets/img/image-placeholder.png' />
        ) : (
          <img className={cssModule.image} data-zoomable='1' src={resource?.url} onClick={showZoomModal} />
        )}

        <StyledResourceTitle>{resource?.title}</StyledResourceTitle>
        <StyledResourceSubtitle>{resource?.subtitle}</StyledResourceSubtitle>
      </StyledResourceCard>
      {isZoomed ? (
        <Modal closeModal={hideZoomModal} fullscreen withCloseButton={false}>
          <ZoomFrame centered className={cssModule.zoomFrame}>
            <img className={cssModule.zoomedImage} src={resource?.url} />
          </ZoomFrame>
          <Button
            className={cssModule.cancelButton}
            icon='cancel'
            inverted
            theme='gray'
            accent='alt'
            size='medium'
            onClick={hideZoomModal}
          />
        </Modal>
      ) : null}
    </>
  )
}

const ImagesContent = ({ premiumResources, onResourceClick }) => {
  const onClick = useCallback(
    resource => {
      onResourceClick(resource)
    },
    [onResourceClick]
  )

  return (
    <>
      <Header premiumResources={premiumResources} />
      <StyledResourcesList>
        {_.map(
          resource => (
            <ImageCard key={resource?.id} resource={resource} onClick={onClick} />
          ),
          premiumResources
        )}
      </StyledResourcesList>
    </>
  )
}

export default ImagesContent
