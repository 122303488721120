import ClassicScreen from '@resources/screens/classic.jsx'
import CoreScreen from '@resources/screens/core.jsx'

const routes = [
  {
    path: '/classique/:bookUri',
    exact: true,
    component: <ClassicScreen />
  },
  {
    path: '/classiques/:bookUri',
    exact: true,
    component: <ClassicScreen />
  },
  {
    path: '/page/:pageId',
    exact: true,
    component: <CoreScreen />
  },
  {
    path: '/manuels/:bookUri',
    exact: true,
    component: <CoreScreen />
  }
]

export default routes
