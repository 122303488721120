import { gqlNoCache } from '@resources/api/graphql'
import { normalizeData } from '@resources/schema'

export const sendCustomLogApi = ({ event, token, pageId, analyticsParams }) =>
  gqlNoCache({
    query: `mutation ($event: AnalyticsEvents!, $token: String, $pageId: Int, $analyticsParams: JSONObject) {
      marketing (token: $token) {
        createAnalytics(event: $event, pageId: $pageId, analyticsParams: $analyticsParams) {
          status
        }
      }
    }`,
    variables: {
      token,
      pageId,
      event,
      analyticsParams
    }
  }).then(normalizeData)
