import { VideoPlayer } from '@lls/lls-kit'
import { useCallback } from 'react'

import Header from './header'

const VideosContent = ({ premiumResources, onResourceClick }) => {
  const videoResource = premiumResources?.[0]

  const onClick = useCallback(() => {
    onResourceClick(videoResource)
  }, [onResourceClick, videoResource])

  return (
    <>
      <VideoPlayer src={premiumResources?.[0]?.url} onPlay={onClick} />
      <Header premiumResources={premiumResources} />
    </>
  )
}

export default VideosContent
