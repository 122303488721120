import cssModule from './common.module.css'

const ASSET_URL = 'https://assets.lls.fr'

export const FloatingAsset = ({ number, id, width, top, bottom, left, right, ...props }) => (
  <img
    className={cssModule.floatingAsset}
    alt={`floating asset ${id}`}
    style={{
      '--r-width': width ?? 'auto',
      '--r-top': top ?? 'auto',
      '--r-bottom': bottom ?? 'auto',
      '--r-left': left ?? 'auto',
      '--r-right': right ?? 'auto'
    }}
    src={`${ASSET_URL}/books/${id}/Asset-${number}.png`}
    {...props}
  />
)
