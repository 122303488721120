import { Core } from '@lls/core'
import useAuth from '@resources/hooks/useAuth'
import { memo } from 'react'

const MemoCore = memo(Core, (old, next) => JSON.stringify(old) === JSON.stringify(next))

const CoreScreen = () => {
  useAuth()
  return <MemoCore appData={{ enableNavbar: false }} />
}

export default CoreScreen
