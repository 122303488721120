const dependencyVersions = {
  // biome-ignore lint/correctness/noUndeclaredVariables:
  viewerVersion: __VIEWER__,
  // biome-ignore lint/correctness/noUndeclaredVariables:
  superkitVersion: __SUPERKIT__,
  // biome-ignore lint/correctness/noUndeclaredVariables:
  kitVersion: __KIT__,
  // biome-ignore lint/correctness/noUndeclaredVariables:
  utilsVersion: __UTILS__,
  // biome-ignore lint/correctness/noUndeclaredVariables:
  gitHash: __GITHASH__
}

const config_dev = {
  env: 'development',
  api_url: 'https://api-dev.lelivrescolaire.fr',
  epub_url: 'https://epub-dev.lelivrescolaire.fr/epub',
  front_url: 'https://dev.lelivrescolaire.fr',
  premium_url: 'https://premium-dev.lelivrescolaire.fr',
  connect_url: 'https://connect-dev.lelivrescolaire.fr',
  ...dependencyVersions
}

const config_test = {
  env: 'test',
  api_url: 'https://api-dev.lelivrescolaire.fr',
  epub_url: 'https://epub-dev.lelivrescolaire.fr/epub',
  front_url: 'https://dev.lelivrescolaire.fr',
  premium_url: 'https://premium-dev.lelivrescolaire.fr',
  connect_url: 'https://connect-dev.lelivrescolaire.fr',
  ...dependencyVersions
}

const config_preprod = {
  env: 'preprod',
  api_url: 'https://api-preprod.lelivrescolaire.fr',
  epub_url: 'https://epub-preprod.lelivrescolaire.fr/epub',
  front_url: 'https://preprod.lelivrescolaire.fr',
  premium_url: 'https://premium-preprod.lelivrescolaire.fr',
  connect_url: 'https://connect-preprod.lelivrescolaire.fr',
  ...dependencyVersions
}

const config_prod = {
  env: 'production',
  api_url: 'https://api.lelivrescolaire.fr',
  epub_url: 'https://epub.lelivrescolaire.fr/epub',
  front_url: 'https://www.lelivrescolaire.fr',
  premium_url: 'https://premium.lelivrescolaire.fr',
  connect_url: 'https://connect.lelivrescolaire.fr',
  ...dependencyVersions
}

const config = env => {
  switch (true) {
    case env === 'preprod':
      return config_preprod
    case env === 'prod':
    case env === 'production':
      return config_prod
    case env === 'test':
      return config_test
    case env === 'local':
    case env === 'dev':
    case env === 'development':
      return config_dev
    default:
      return config_dev
  }
}
const conf_env = process.env.CONF_ENV ?? 'development'
/* eslint-disable no-console */
console.info('Loaded conf: ', conf_env)
export default config(conf_env)
