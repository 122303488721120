export const makeNotification = ({ name, message, type, params }) => {
  switch (true) {
    case name === 'success':
      return { name, message, severity: 'success', type, params }
    case name === 'error':
      return { name, message, severity: 'error', type, params }
    case name === 'warning':
      return { name, message, severity: 'warning', type, params }
    default:
      return { name, message, type, params }
  }
}
