import { useCallback } from 'react'

import _ from 'lodash/fp'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from '@lls/lls-kit'

import { getCurrentUser } from '@resources/selectors/user'
import { getPath } from '@resources/utils/route'
import { BREAKPOINT_SM, getFluidSize } from '@resources/utils/style'
import cssModule from './classicFooter.module.css'
import { FloatingAsset } from './common'

const ASSET_PROPS_FOOTER = {
  4: {
    width: getFluidSize({ minViewport: BREAKPOINT_SM, minSize: 120, maxSize: 140 }),
    bottom: 0,
    left: 0
  },
  5: {
    width: getFluidSize({ minViewport: BREAKPOINT_SM, minSize: 100, maxSize: 120 }),
    top: '-80px',
    right: 0
  }
}

const ClassicFooter = ({ book }) => {
  const history = useHistory()
  const user = useSelector(getCurrentUser)
  const onClassicsBookClick = useCallback(() => {
    if (user?.isPremium) {
      return window.open(getPath('external_premium_book', { uri: book?.uri }))
    }
    history.push(getPath('book', { uri: book?.uri }))
  }, [user, book, history])

  return (
    <div className={cssModule.container}>
      <img src='/assets/img/footer-border.png' />
      <div
        className={cssModule.content}
        style={{
          '--r-content-padding': `${getFluidSize({
            minSize: 24,
            maxSize: 85
          })} ${getFluidSize({
            minSize: 16,
            maxSize: 80
          })}`
        }}
      >
        <div className={cssModule.block}>
          <h1 className={cssModule.title}>L'œuvre numérique</h1>
          <h3 className={cssModule.subtitle}>Accédez à votre Classique au format epub !</h3>
          <Button
            disabled={!book?.epub}
            theme='premium'
            href={getPath('external_epub', { uri: book?.uri })}
            onGoTo={({ href }) => window.open(href, '__blank')}
          >
            L'œuvre numérique
          </Button>
        </div>
        <div className={cssModule.block}>
          <h1 className={cssModule.title}>Dossier pédagogique</h1>
          <h3 className={cssModule.subtitle}>Découvrez le dossier complet en numérique.</h3>
          <Button theme='premium' onClick={onClassicsBookClick}>
            Dossier pédagogique
          </Button>
        </div>
      </div>
      {_.flow(
        _.toPairs,
        _.map(([number, props]) => <FloatingAsset key={number} number={number} id={book?.id} {...props} />)
      )(ASSET_PROPS_FOOTER)}
    </div>
  )
}

export default ClassicFooter
