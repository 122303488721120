import _ from 'lodash/fp'
import { normalize, schema } from 'normalizr'

const user = new schema.Entity('users')
const book = new schema.Entity('books')
const subject = new schema.Entity('subjects')
const level = new schema.Entity('levels')
const premiumResource = new schema.Entity('premiumResources')

book.define({
  subjects: [subject],
  levels: [level],
  textbooks: [book],
  workbooks: [book],
  oldBook: book,
  premiumResources: [premiumResource]
})

user.define({
  books: [book],
  booksV2: [book],
  subjects: [subject],
  levels: [level]
})

export const querySchema = {
  viewer: {
    books: { hits: [book] },
    me: user
  },
  auth: {
    user
  }
}

export const normalizeData = _.flow(data => normalize(data, querySchema))
