import config from '@resources/config/index.jsx'

export const getPath = (routeName, params = {}) => {
  switch (routeName) {
    case 'book': {
      return `/manuels/${params?.uri}`
    }
    case 'external_epub':
      return `${config.epub_url}/${params.uri}`
    case 'external_book':
      return `${config.front_url}/manuels/${params.uri}`
    case 'external_premium_book':
      return `${config.premium_url}/manuels/${params.uri}`
    case 'external_classics_access':
      return `${config.connect_url}/acces-classique/${params.uri}?redirect=${window.location}`
    default:
      return routeName
  }
}
