import { setLoadingScreen } from '@resources/actions'
import { LOADED_STATUS, LOADING_STATUS, SCREEN_GLOBAL } from '@resources/constants'
import { getScreenLoadingEventName } from '@resources/utils/loading'
import _ from 'lodash/fp'

export const tryCatch = async ({ dispatch, loadingName, effect = _.noop, params = {}, silent = false, ...rest }) => {
  const screenEvent = loadingName ? getScreenLoadingEventName(loadingName, params) : SCREEN_GLOBAL

  try {
    !silent && dispatch(setLoadingScreen({ [screenEvent]: LOADING_STATUS }))
    return await effect({ params, dispatch, ...rest })
  } catch (e) {
    /* eslint-disable no-console */
    console.error('effect error : ', e)
  } finally {
    !silent && dispatch(setLoadingScreen({ [screenEvent]: loadingName ? LOADED_STATUS : null }))
  }
}
