import _ from 'lodash/fp'

import { LOADED_STATUS, LOADING_STATUS } from '@resources/constants/index.js'
import { getScreenLoadingEventName } from '@resources/utils/loading.jsx'

export const getLoadingStatus = (state, screen, params) => {
  const loadingState = _.get('loading', state)
  return _.flow(
    screen => getScreenLoadingEventName(screen, params),
    screenLoadingEventName => _.get(screenLoadingEventName, loadingState)
  )(screen)
}

export const getIsAppLoading = _.flow(_.get('loading'), _.omitBy(_.isNil), _.negate(_.every(_.isEqual(LOADED_STATUS))))

export const getIsLoading = _.flow(getLoadingStatus, _.isEqual(LOADING_STATUS))

export const getIsLoaded = _.flow(getLoadingStatus, _.isEqual(LOADED_STATUS))
