import { LOADED_STATUS, LOADING_STATUS, SCREEN_CLASSIC } from '@resources/constants/index.js'
import _ from 'lodash/fp'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addNotification, setEntities, setLoadingScreen } from '@resources/actions/index.js'
import { fetchClassicScreen } from '@resources/api/screen.js'
import { getLoadingStatus } from '@resources/selectors/loading.js'
import { getScreenLoadingEventName } from '@resources/utils/loading.jsx'
import { makeNotification } from '@resources/utils/notification.jsx'

const fetchData = ({ screen, params = {} }) => {
  switch (screen) {
    case SCREEN_CLASSIC:
      return fetchClassicScreen(params)
  }
}

export const fetch = async ({ dispatch, screen, params = {} }) => {
  const screenEvent = getScreenLoadingEventName(screen, params)
  dispatch(setLoadingScreen({ [screenEvent]: LOADING_STATUS }))
  const result = await fetchData({ screen, params })
  const entities = _.get('entities', result)

  dispatch(setEntities(entities))
  dispatch(setLoadingScreen({ [screenEvent]: LOADED_STATUS }))

  return result
}

export const useFetch = ({ screen, params = {}, requiredParam }) => {
  const loading = useSelector(state => getLoadingStatus(state, screen, params))
  const dispatch = useDispatch()
  const shouldFetch = _.isNil(loading) && (_.get(requiredParam, params) || _.isNil(requiredParam))

  useEffect(() => {
    async function fetchEffect() {
      if (shouldFetch) {
        try {
          await fetch({ dispatch, screen, params })
        } catch (_e) {
          dispatch(addNotification(makeNotification({ name: 'fetch_error' })))
        }
      }
    }
    fetchEffect()
  }, [dispatch, screen, params, shouldFetch])
}
