import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { KIT_CLASSNAME_LIGHT_THEME, styleThemeProps } from '@lls/lls-kit'

import '@lls/core/clientImports'
import '@lls/lls-kit/clientImports'
import '@lls/superkit/clientImports'
import { cx } from '@lls/utils'
import '@lls/viewer/clientImports'
import Analytics from '@resources/components/analytics'
import routes from '@resources/routes.jsx'
import configureStore from '@resources/store/index.js'
import cssModule from './app.module.css'
import config from './config/index.jsx'

const store = configureStore()

const App = () => {
  useEffect(() => {
    const build = {
      node_env: process.env.NODE_ENV,
      config: config.env,
      gitHash: config.gitHash,
      viewerVersion: config.viewerVersion,
      superkitVersion: config.superkitVersion,
      kitVersion: config.kitVersion,
      utilsVersion: config.utilsVersion
    }
    console.info(build)
  }, [])
  return (
    <div
      style={styleThemeProps({
        isDysMode: false,
        sizeIndex: 2
      })}
      className={cx(KIT_CLASSNAME_LIGHT_THEME, cssModule.accessibilityElement)}
    >
      <Analytics />
      <Switch>
        {routes?.map(({ path, component, exact }) => (
          <Route path={path} key={path} exact={exact}>
            {component}
          </Route>
        ))}
      </Switch>
    </div>
  )
}
const root = createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
)
