import _ from 'lodash/fp.js'
import React from 'react'

import { BookPremiumResources } from '@lls/superkit'
import { ANALYTICS_LINK_CLICKED, dispatchAnalyticsEvent, openWindow } from '@lls/utils'

import { BREAKPOINT_SM, getFluidSize } from '@resources/utils/style'

import { getPath } from '@resources/utils/route'
import cssModule from './classicResources.module.css'
import ClassicResourcesModal from './classicResourcesModal'
import { FloatingAsset } from './common'

const ASSET_PROPS = {
  1: {
    width: getFluidSize({ minViewport: BREAKPOINT_SM, minSize: 160, maxSize: 200 }),
    top: '-180px',
    right: 0
  },
  2: {
    width: '250px',
    top: '-100px',
    left: '50%'
  },
  3: {
    width: getFluidSize({ minViewport: BREAKPOINT_SM, minSize: 160, maxSize: 250 }),
    top: '-120px',
    left: 0
  }
}

const ClassicResources = ({ premiumResources, book, isAuthorized }) => {
  const resourcesByMetaCategories = _.flow(_.sortBy('order'), _.groupBy('metaCategory.title'))(premiumResources)

  const onResourceClick = ({ title, category } = {}) => {
    dispatchAnalyticsEvent(ANALYTICS_LINK_CLICKED, {
      featureName: 'classic_modal_resource',
      resourceId: [`${category?.title}|${title}`]
    })
  }

  const onCategoryClick = ({ metaCategory, title, downloadBlocked } = {}) => {
    dispatchAnalyticsEvent(ANALYTICS_LINK_CLICKED, {
      featureName: 'classic_carousel_card',
      resourceId: [`${metaCategory?.title}|${title}`]
    })
    downloadBlocked && !isAuthorized && openWindow(getPath('external_classics_access', { uri: book?.uri }), '_self')
  }
  return (
    <div
      className={cssModule.container}
      style={{
        '--r-container-padding': `${getFluidSize({
          minSize: 12,
          maxSize: 82
        })} ${getFluidSize({
          minSize: 12,
          maxSize: 80
        })}`
      }}
    >
      {_.map.convert({ cap: false })(
        (group, index) => (
          <React.Fragment key={index}>
            <BookPremiumResources
              title={<h1 className={cssModule.title}>{group?.[0]?.metaCategory?.title}</h1>}
              subtitle={<h2 className={cssModule.subtitle}>{group?.[0]?.metaCategory?.subtitle}</h2>}
              displayCardShadow={false}
              displayTypeStamp
              onResourceClick={onResourceClick}
              onCategoryClick={onCategoryClick}
              premiumResources={group}
              ModalComponent={ClassicResourcesModal}
            />
          </React.Fragment>
        ),
        resourcesByMetaCategories
      )}
      {_.flow(
        _.toPairs,
        _.map(([number, props]) => <FloatingAsset key={number} number={number} id={book?.id} {...props} />)
      )(ASSET_PROPS)}
    </div>
  )
}

export default ClassicResources
