import { normalizeData } from '@resources/schema/index.js'
import { gqlCached } from './graphql'

export const fetchClassicScreen = ({ bookUri }) => {
  return gqlCached({
    query: `query resourcesFetchClassicScreen ($bookUri: String!) {
      viewer {
        books (uris: [$bookUri], hasNewDesign: true, isValid: true) {
          hits {
            id
            displayTitle
            uri
            epub
            classicColor
            isClassicTextDark
            premiumResources {
              id
              title
              subtitle
              type
              url
              order
              comingSoon
              downloadBlocked
              book {
                id
              }
              category {
                title
                subtitle
                imgUrl
                comingSoon
              }
              metaCategory {
                title
                subtitle
              }
              mediaType
            }
          }
        }
      }
    }
    `,
    variables: {
      bookUri
    }
  }).then(normalizeData)
}
