import { gqlNoCache } from '@resources/api/graphql'

export const fetchAllowedBookUrisApi = ({ classicsToken }) =>
  gqlNoCache({
    query: `query resourcesFetchAllowedBookUrisApi ($classicsToken: String!) {
        marketing {
          getBookUrisFromToken(classicsToken: $classicsToken)
        }
      }`,
    variables: {
      classicsToken
    }
  })
