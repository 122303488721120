import {
  createSearchSelector,
  filterEntitiesSimpleFieldByValues,
  makeGetAllEntities,
  makeGetEntityById
} from '@lls/utils'
import _ from 'lodash/fp'

export const getBooks = makeGetAllEntities('books')

export const getBook = makeGetEntityById('books')

const filterBooks = (books, { ids, sortByQuery, uris }) =>
  _.flow(
    filterEntitiesSimpleFieldByValues('id', ids),
    filterEntitiesSimpleFieldByValues('uri', uris),
    _.isFunction(sortByQuery) ? sortByQuery : _.sortBy(sortByQuery)
  )(books)

export const makeSearchBooks = () =>
  createSearchSelector(
    getBooks,
    (_state, params) => params,
    (books, params) => filterBooks(books, { ...params })
  )

// IMPORTANT : this selector is to use ONLY for new DA books, which are alternative & not valid books (temporary state)
export const searchBooks = makeSearchBooks()
