import { useSelector } from 'react-redux'

import { Loader } from '@lls/lls-kit'

import { getIsLoading } from '@resources/selectors/loading.js'

const Loading = ({ screen, params }) => {
  const isLoading = useSelector(state => getIsLoading(state, screen, params))
  return isLoading && <Loader />
}

export default Loading
