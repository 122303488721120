import {
  createSearchSelector,
  filterEntitiesSimpleFieldByValues,
  makeGetAllEntities,
  makeGetEntityById
} from '@lls/utils'
import _ from 'lodash/fp'

export const getPages = makeGetAllEntities('pages')

export const getPage = makeGetEntityById('pages')

const filterPages = (pages, { ids, slug }) =>
  _.flow(filterEntitiesSimpleFieldByValues('id', ids), filterEntitiesSimpleFieldByValues('slug', slug))(pages)

export const makeSearchPages = () =>
  createSearchSelector(
    getPages,
    (_state, params) => params,
    (pages, params) => filterPages(pages, { ...params })
  )

export const searchPages = makeSearchPages()
