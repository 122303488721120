import { Modal } from '@lls/lls-kit'
import _ from 'lodash/fp'

import { cx } from '@lls/utils'
import AudiosContent from './audiosContent'
import { ModalFloatingCloseButton } from './common'
import ImagesContent from './imagesContent'
import cssModule from './index.module.css'
import InteractiveContent from './interactiveContent'
import LinksContent from './linksContent'
import VideosContent from './videosContent'

const ModalContent = ({ premiumResources, onResourceClick }) => {
  const mediaType = premiumResources?.[0]?.mediaType

  switch (mediaType) {
    case 'image':
      return <ImagesContent premiumResources={premiumResources} onResourceClick={onResourceClick} />
    case 'link':
      return <LinksContent premiumResources={premiumResources} onResourceClick={onResourceClick} />
    case 'video':
      return <VideosContent premiumResources={premiumResources} onResourceClick={onResourceClick} />
    case 'audio':
      return <AudiosContent premiumResources={premiumResources} onResourceClick={onResourceClick} />
    case 'genially':
      return (
        <InteractiveContent premiumResources={premiumResources} onResourceClick={onResourceClick} name='genially' />
      )
    case 'padlet':
      return <InteractiveContent premiumResources={premiumResources} onResourceClick={onResourceClick} name='padlet' />
  }
}

const ClassicResourceModal = ({ premiumResources, className = '', onClose, onResourceClick = _.noop, ...props }) => (
  <Modal
    closeModal={onClose}
    className={cx(className, cssModule.modal)}
    maxwidth='sm'
    withCloseButton={false}
    {...props}
  >
    <ModalContent premiumResources={premiumResources} onResourceClick={onResourceClick} />
    <ModalFloatingCloseButton onClick={onClose} />
  </Modal>
)

export default ClassicResourceModal
