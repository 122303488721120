import {
  createSearchSelector,
  filterEntitiesSimpleFieldByValues,
  makeGetAllEntities,
  makeGetEntityById
} from '@lls/utils'
import _ from 'lodash/fp'
import { createSelector } from 'reselect'

import { getAuth } from '@resources/selectors/auth.js'

export const getUsers = makeGetAllEntities('users')
export const getUser = makeGetEntityById('users')

export const getCurrentUser = createSelector(getAuth, getUsers, (auth, users) =>
  _.find({ id: _.get('user.id', auth) }, users)
)

export const makeSearchUsers = () =>
  createSearchSelector(
    getUsers,
    (_state, params) => params,
    (users, { ids }) => _.flow(filterEntitiesSimpleFieldByValues('id', ids))(users)
  )

export const searchUsers = makeSearchUsers()
