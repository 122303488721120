import { getFluidSize } from '@resources/utils/style'
import cssModule from './classicHeader.module.css'

const ASSET_URL = 'https://assets.lls.fr'

const ClassicHeader = ({ book }) => {
  const id = book?.id
  return (
    <div
      className={cssModule.container}
      style={{
        '--r-color': book?.classicColor,
        '--r-bg-image-url': id ? `url('${ASSET_URL}/books/${id}/header-horiz.png')` : 'none',
        '--r-container-padding': `${getFluidSize({
          minSize: 20,
          maxSize: 120
        })} ${getFluidSize({
          minSize: 12,
          maxSize: 80
        })}`,
        '--r-logo-width': getFluidSize({
          minSize: 150,
          maxSize: 300
        }),
        '--r-logo-height': getFluidSize({
          minSize: 38,
          maxSize: 76
        }),
        '--r-title-font-size': getFluidSize({
          minSize: 40,
          maxSize: 64
        }),
        '--r-title-line-height': getFluidSize({
          minSize: 44,
          maxSize: 60
        })
      }}
      id={id}
    >
      <img
        className={cssModule.logo}
        src={book?.isClassicTextDark ? '/assets/img/logo-classics-black.svg' : '/assets/img/logo-classics-white.svg'}
        alt='logo'
      />

      <h1 className={cssModule.title} data-dark-color={+!!book?.isClassicTextDark}>
        {book?.displayTitle}
      </h1>
      <h3 className={cssModule.subtitle} data-dark-color={+!!book?.isClassicTextDark}>
        Retrouvez sur cette page, l'ensemble des ressources disponibles en lien avec votre classique préféré !
      </h3>
    </div>
  )
}

export default ClassicHeader
