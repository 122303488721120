import { Button } from '@lls/lls-kit'
import _ from 'lodash/fp'
import { useCallback } from 'react'

import { StyledResourceCard, StyledResourceSubtitle, StyledResourceTitle, StyledResourcesList } from './common'

import Header from './header'

const LinkCard = ({ resource, onClick }) => {
  const onClickCb = useCallback(() => {
    if (resource?.comingSoon) return
    onClick(resource)
    window.open(resource?.url, '_blank')
  }, [onClick, resource])

  return (
    <StyledResourceCard>
      <StyledResourceTitle>{resource?.title}</StyledResourceTitle>
      <StyledResourceSubtitle>{resource?.subtitle}</StyledResourceSubtitle>
      <Button
        disabled={!!resource?.comingSoon}
        onClick={onClickCb}
        theme='premium'
        icon='arrowdowntoline'
        href={resource?.comingSoon ? null : resource?.url}
      >
        {resource?.comingSoon || 'Télécharger'}
      </Button>
    </StyledResourceCard>
  )
}

const LinksContent = ({ premiumResources, onResourceClick }) => (
  <>
    <Header premiumResources={premiumResources} />
    <StyledResourcesList>
      {_.map(
        resource => (
          <LinkCard key={resource?.id} resource={resource} onClick={onResourceClick} />
        ),
        premiumResources
      )}
    </StyledResourcesList>
  </>
)

export default LinksContent
