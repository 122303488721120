import config from '@resources/config/index.jsx'
import _ from 'lodash/fp'
import axios from './axios'

const handleResponse = ({ data }) => {
  if (data.errors) {
    throw data.errors[0]
  }
  return data.data
}

const compressQuery = _.replace(/\s+/g, ' ')

export const gqlCached = ({ query, variables }) => {
  const condensedQuery = compressQuery(query)
  const stringifiedVariables = JSON.stringify(variables)
  return axios
    .get(
      `${config.api_url}/graph-cached?query=${condensedQuery}${
        stringifiedVariables ? `&variables=${stringifiedVariables}` : ''
      }`
    )
    .then(handleResponse)
}

export const gqlNoCache = body => axios.post(`${config.api_url}/graph`, body).then(handleResponse)
