import _ from 'lodash/fp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import {
  ANALYTICS_PAGE_VIEWED,
  dispatchAnalyticsEvent,
  getQueryParams,
  sendEvent,
  useAnalyticsEventEnhancer
} from '@lls/utils'

import ClassicFooter from '@resources/components/classic/classicFooter'
import ClassicHeader from '@resources/components/classic/classicHeader'
import ClassicResources from '@resources/components/classic/classicResources'
import Loading from '@resources/components/loading'
import { SCREEN_CLASSIC } from '@resources/constants'
import useAuth from '@resources/hooks/useAuth'
import { useFetch } from '@resources/hooks/useFetch.js'
import { searchBooks } from '@resources/selectors/book'
import { getIsLoaded } from '@resources/selectors/loading'
import { searchPremiumResources } from '@resources/selectors/premiumResource'

const Classic = () => {
  const params = useParams()
  const { at_medium, at_campaign } = getQueryParams(useLocation()) || {}
  const bookUri = params?.bookUri

  const isAuthorized = useAuth(false)

  const book = _.first(useSelector(state => searchBooks(state, { uris: [bookUri] })))
  const premiumResources = useSelector(state => searchPremiumResources(state, { ids: book?.premiumResources }))

  useFetch({ screen: SCREEN_CLASSIC, params: { bookUri } })

  useAnalyticsEventEnhancer({
    bookId: _.get('id', book),
    pageSource: `${at_medium}${at_campaign ? `|${at_campaign}` : ''}`
  })

  const screenReady = useSelector(state => getIsLoaded(state, SCREEN_CLASSIC, { bookUri }))
  useEffect(() => {
    if (!screenReady) return

    dispatchAnalyticsEvent(ANALYTICS_PAGE_VIEWED)
    sendEvent({ name: 'page.display' })
  }, [screenReady])

  return (
    <>
      <Loading screen={SCREEN_CLASSIC} params={{ bookUri }} />
      <ClassicHeader book={book} />
      <ClassicResources book={book} premiumResources={premiumResources} isAuthorized={isAuthorized} />
      <ClassicFooter book={book} />
    </>
  )
}

export default Classic
