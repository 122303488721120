export const SCREEN_GLOBAL = 'screen_global'
export const SCREEN_CLASSIC = 'screen_classic'
export const SCREEN_CORE = 'screen_core'

export const LOADING_STATUS = 'loading_status'
export const LOADED_STATUS = 'loaded_status'

export const COOKIE_USER = 'jwt_token'
export const COOKIE_JWT_CLASSICS = 'jwt_classics'

export const COOKIE_ACCESSIBILITY_STATE = 'accessibility_state'
