import { AUDIO_CLASSNAME_LIGHT, Player } from '@lls/lls-audio'
import _ from 'lodash/fp'
import { useCallback, useMemo } from 'react'

import { cx } from '@lls/utils'
import cssModule from './audiosContent.module.css'
import Header from './header'

const AudiosContent = ({ premiumResources, onResourceClick }) => {
  const validResources = useMemo(
    () => _.filter(resource => _.isEmpty(resource?.comingSoon), premiumResources),
    [premiumResources]
  )

  const onClick = useCallback(
    play => {
      if (play) onResourceClick(premiumResources?.[0]) //TODO: improve because we don't know which file is played...
    },
    [onResourceClick, premiumResources]
  )

  const downloadBlocked = _.some({ downloadBlocked: true })(validResources)

  return (
    <>
      <Header premiumResources={validResources} />
      <div className={cssModule.content}>
        <div className={cx(AUDIO_CLASSNAME_LIGHT, cssModule.playerContainer)}>
          <Player
            downloadAvailable={!downloadBlocked}
            onPlayPause={onClick}
            audioFiles={_.map(
              resource => ({
                src: resource?.url,
                title: resource?.title,
                credit: resource?.subtitle
              }),
              validResources
            )}
          />
        </div>
      </div>
    </>
  )
}

export default AudiosContent
