import { useCallback, useReducer } from 'react'

import _ from 'lodash/fp'

import { Button, Modal } from '@lls/lls-kit'
import { ModalFloatingCloseButton, StyledResourceTitle } from './common'
import Header from './header'
import cssModule from './interactiveContent.module.css'

const InteractiveContentFullscreenModal = ({ interactiveResource, onCloseModal }) => (
  <Modal className={cssModule.modal} fullscreen fullwidth closeModal={onCloseModal} withCloseButton={false}>
    <div className={cssModule.fullscreenContainer}>
      <iframe height='100%' width='100%' src={interactiveResource?.url} type='text/html' allowFullScreen />
      <ModalFloatingCloseButton onClick={onCloseModal} />
    </div>
  </Modal>
)

const InteractiveContent = ({ premiumResources, onResourceClick, name }) => {
  const [open, toggleOpen] = useReducer(value => !value, false)

  const interactiveResource = premiumResources?.[0]

  const onClick = useCallback(() => {
    onResourceClick(interactiveResource)
    toggleOpen()
  }, [onResourceClick, interactiveResource])

  return (
    <>
      <div className={cssModule.container}>
        <img src={`/assets/img/${name}.png`} />
        <div className={cssModule.content}>
          <StyledResourceTitle>{_.capitalize(name)}</StyledResourceTitle>
          <Button onClick={onClick}>Accéder au module</Button>
        </div>
      </div>
      <Header premiumResources={premiumResources} />
      {!!open && (
        <InteractiveContentFullscreenModal interactiveResource={interactiveResource} onCloseModal={toggleOpen} />
      )}
    </>
  )
}

export default InteractiveContent
