import _ from 'lodash/fp'

import { setAuth, setEntities } from '@resources/actions'
import { fetchAllowedBookUrisApi } from '@resources/api/marketing'
import { fetchUserApi } from '@resources/api/user'
import { COOKIE_JWT_CLASSICS, COOKIE_USER } from '@resources/constants'
import { tryCatch } from '@resources/effects/utils'
import { removeCookie } from '@resources/utils/cookie'

export const fetchUser = async ({ dispatch, params: { userToken, onComplete = _.noop, onFail = _.noop } = {} }) => {
  try {
    const { entities } = await fetchUserApi({ token: userToken })
    const user = _.flow(_.get('users'), _.values, _.first)(entities)
    dispatch(setEntities(entities))
    dispatch(setAuth({ user }))
    onComplete(user)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('fetchUser fail:', error?.message)
    removeCookie(COOKIE_USER)
    onFail()
  }
}

export const fetchAllowedBooks = async ({
  dispatch,
  params: { classicsToken, onComplete = _.noop, onFail = _.noop } = {}
}) => {
  try {
    const data = await fetchAllowedBookUrisApi({ classicsToken })
    const allowedBooks = _.get('marketing.getBookUrisFromToken', data)
    dispatch(setAuth({ allowedBooks }))
    onComplete(allowedBooks)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('fetchAllowedBooks fail:', error?.message)
    removeCookie(COOKIE_JWT_CLASSICS)
    onFail()
  }
}

export const fetchUserEffect = ({ dispatch, params }) => tryCatch({ dispatch, params, effect: fetchUser, silent: true })

export const fetchAllowedBooksEffect = ({ dispatch, params }) =>
  tryCatch({ dispatch, params, effect: fetchAllowedBooks, silent: true })
