import { getFontSizeValue } from '@lls/lls-kit'
import { getQueryParams, getWindow, useAnalyticsEvent } from '@lls/utils'
import { sendCustomLogApi } from '@resources/api/analytics'
import { COOKIE_ACCESSIBILITY_STATE, COOKIE_USER } from '@resources/constants'
import { getCurrentUser } from '@resources/selectors/user'
import { getCookie } from '@resources/utils/cookie'
import _ from 'lodash/fp'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const Analytics = () => {
  const token = getCookie(COOKIE_USER)
  const piano = getWindow()?.pa
  const anonymousId = piano?.getVisitorId()
  const userId = useSelector(getCurrentUser)?.id
  const { sizeIndex, fontFamily, isDarkMode, isDysMode } = getCookie(COOKIE_ACCESSIBILITY_STATE)?.[userId] || {}
  const qaFlags = _.flow(getQueryParams, _.get('qa'))(useLocation())
  useAnalyticsEvent({
    token,
    anonymousId,
    getFontSizeValue,
    send: sendCustomLogApi,
    qaFlags,
    sizeIndex,
    fontFamily,
    isDarkMode,
    isDysMode
  })
  return null
}

export default Analytics
