import { openWindow } from '@lls/utils'
import { COOKIE_JWT_CLASSICS, COOKIE_USER } from '@resources/constants/index.js'
import { fetchAllowedBooksEffect, fetchUserEffect } from '@resources/effects/auth'
import { getBook, makeSearchBooks } from '@resources/selectors/book'
import { makeSearchPages } from '@resources/selectors/page'
import { getCookie } from '@resources/utils/cookie.jsx'
import { getPath } from '@resources/utils/route'
import _ from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const AUTH_TO_BE_DONE = 'AUTH_TO_BE_DONE'

const useAuth = (shouldRedirect = true) => {
  const dispatch = useDispatch()
  const searchPages = useMemo(() => makeSearchPages(), [])
  const searchBooks = useMemo(() => makeSearchBooks(), [])
  const [authUser, setAuthUser] = useState(AUTH_TO_BE_DONE)
  const [allowedBooks, setAllowedBooks] = useState(AUTH_TO_BE_DONE)
  const params = useParams()

  // handle redirect according to auth
  const book = useSelector(state => {
    const pageId = params?.pageId
    const slug = `page/${pageId}`
    const page = searchPages(state, { slug })?.[0]
    const bookPage = getBook(state, page?.book)
    const bookUri = bookPage?.uri || params?.bookUri
    return searchBooks(state, { uris: [bookUri] })?.[0]
  }, shallowEqual)

  // auth user
  useEffect(() => {
    const fetchUser = async () => {
      const userToken = getCookie(COOKIE_USER)
      if (userToken) {
        await fetchUserEffect({ dispatch, params: { userToken, onComplete: setAuthUser, onFail: setAuthUser } })
      } else {
        setAuthUser(null)
      }
    }

    const fetchAllowedBooks = async () => {
      const classicsToken = getCookie(COOKIE_JWT_CLASSICS)
      if (classicsToken) {
        await fetchAllowedBooksEffect({
          dispatch,
          params: { classicsToken, onComplete: setAllowedBooks, onFail: setAllowedBooks }
        })
      } else {
        setAllowedBooks([])
      }
    }

    Promise.all([fetchUser(), fetchAllowedBooks()])
  }, [dispatch])

  const isAuthorized = _.includes(book?.id, authUser?.booksV2) || _.includes(book?.uri, allowedBooks)

  useEffect(() => {
    if (book && authUser !== AUTH_TO_BE_DONE && allowedBooks !== AUTH_TO_BE_DONE && !isAuthorized && shouldRedirect) {
      openWindow(getPath('external_classics_access', { uri: book.uri }), '_self')
    }
  }, [authUser, allowedBooks, book, isAuthorized, shouldRedirect])
  return isAuthorized
}

export default useAuth
